import 'dayjs/locale/es'
import 'dayjs/locale/pt'

import dayjs, {Dayjs} from 'dayjs'
import numbro from 'numbro'
import * as settings from 'settings'
import Decimal from 'decimal.js'

// Date & Time

export const formatTimestamp = (date: string | undefined | Dayjs, format: string) => {
    if (!date) return ''
    return dayjs(date).utcOffset(settings.TIMEZONE).format(format)
}

export const formatDate = (date?: string | Dayjs) => formatTimestamp(date, settings.DATE_FORMAT)
export const formatDateTime = (date?: string) => formatTimestamp(date, settings.DATETIME_FORMAT)
export const formatTime = (date?: string) => formatTimestamp(date, settings.TIME_FORMAT)

// Numbers

export const formatNumber = (number: number): string => {
    return numbro(number).format({
        trimMantissa: true,
    })
}

export const formatPercentage = (number: number | Decimal): string => {
    return numbro(number instanceof Decimal ? number.toNumber() : number).format({
        output: 'percent',
        trimMantissa: true,
        spaceSeparated: true,
    })
}

export const formatCurrency = (
    number: number | Decimal,
    options: {decimals?: number} = {},
    numbroOptions?: numbro.Format,
): string => {
    const result = numbro(number instanceof Decimal ? number.toNumber() : number).format({
        output: 'currency',
        currencySymbol: settings.CURRENCY_CODE,
        spaceSeparated: true,
        ...numbroOptions,
        mantissa: options.decimals === undefined ? Number(settings.MAX_DECIMALS) : options.decimals,
    })
    // Use a non-breaking space so currency code doesn't wrap to another line.
    // https://en.wikipedia.org/wiki/Non-breaking_space
    return result.replace(' ', '\u00A0')
}
